import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Seo from "@components/Seo"
import { Showcase } from "@components/sections/Showcase"
import Heading from "@components/ui/Heading"
import Spacer from "@components/ui/Spacer"

export default function ShowcaseServicePage({ data }) {
  const page = data.allWpService.nodes[0]

  const { acfShowcase } = page

  return (
    <>
      <Seo {...page.seo} />
      <Helmet
        bodyAttributes={{
          class: "short",
        }}
      />

      <div className="container">
        <Spacer>
          <Heading underlined>
            {acfShowcase?.showcasePageTitle || page.title}
          </Heading>
        </Spacer>

        {acfShowcase?.showcases &&
          acfShowcase.showcases.map((element, key) => {
            return <Showcase key={key} {...element} />
          })}
      </div>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    allWpService(
      filter: {
        template: { templateName: { eq: "Showcase" } }
        slug: { eq: $slug }
      }
    ) {
      nodes {
        id
        uri
        title
        link
        seo {
          canonical
          cornerstone
          focuskw
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          opengraphImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FIXED)
              }
            }
          }
          title
          twitterDescription
          twitterTitle
          schema {
            raw
          }
        }
        acfShowcase {
          showcases {
            title
            content
            url
            friendlyUrl
            showcaseImage {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(quality: 50, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`
