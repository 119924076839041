import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import SocialLinks from "@components/ui/social/SocialLinks"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import useMobile from "@components/utils/useMobile"
import classnames from "classnames"
import Heading from "@components/ui/Heading"

const ShowCaseStyled = styled.section`
  margin-bottom: 40px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgray;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    flex-direction: row;
    margin-bottom: 60px;
    padding-bottom: 0;
    border-bottom: none;
  }

  h2 {
    margin-bottom: 31px;
    word-break: break-word;
  }

  .content {
    font-size: 16px;
    color: #545454;
    margin-bottom: 44px;
    line-height: 1.75;
  }

  .social-icons {
    margin-bottom: 35px;
  }

  .meta-data {
    color: var(--color-primary);
    margin-top: 25px;

    .meta {
      text-decoration: none;
    }

    .fa {
      margin-right: 15px;
    }
  }

  .sc-image {
    box-shadow: 0px 0px 29.75px 5.25px rgba(0, 0, 0, 0.18);
    width: 100%;
  }

  .sc-content {
    width: 100%;
    padding-right: 0;
    order: 2;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      width: 28%;
      padding-right: 4%;
      order: 1;
    }
  }

  .sc-image-wrap {
    width: 100%;
    order: 1;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      order: 2;
      width: 72%;
    }
  }
`

export const Showcase = ({
  title,
  content,
  social,
  friendlyUrl,
  showcaseImage,
  url,
}) => {
  const { isMobile } = useMobile()
  return (
    <ShowCaseStyled
      className={classnames({
        "is-mobile": isMobile,
      })}
    >
      {showcaseImage && (
        <div className="sc-image-wrap">
          <a href={url} target="_blank" rel="noreferrer">
            <CustomGatsbyImage {...showcaseImage.localFile} />
          </a>
        </div>
      )}
      <div className="sc-content">
        {title && (
          <Heading node="h2" variant="h3" underlined>
            {title}
          </Heading>
        )}
        {content && (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}

        {url && (
          <>
            <SocialLinks url={url} />
            <div className="meta-data">
              <a
                className="meta site-link"
                href={url}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="globe"
                  className="svg-inline--fa fa-globe fa-w-16"
                  role="img"
                  viewBox="0 0 496 512"
                >
                  <path
                    fill="currentColor"
                    d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"
                  />
                </svg>{" "}
                {friendlyUrl || url}
              </a>
            </div>
          </>
        )}
      </div>
    </ShowCaseStyled>
  )
}

Showcase.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  url: PropTypes.string,
  friendlyUrl: PropTypes.string,
  showcaseImage: PropTypes.object,
}
